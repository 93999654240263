import React, { Fragment, useEffect, useState } from "react";
import { Box, Button, Grid, Typography, Paper, TextField, Divider, Select, MenuItem, InputAdornment} from "@mui/material";


const activeStyle={
    color:'#F5BD41',
}


const disableStyle={
    color: '#EDE6E3',

}

const FormTextField = ({name, label, value, setValueData, disabled, width, required, type, xs, mdlabel, mdTextField , maxNum, lg, xl, decoration, multiline=false}) => {

   const [formValue, setValue] = useState('')
   const [error, setError] =  useState(false)
   useEffect(()=> {
       if(value){
            setValue(value)
       }
       
},[value])


const handleChange=(e)=>{
    setValue(e.target.value)
    setValueData(name, e.target.value)

    if(required && e.target.value ===''){
        setError(true)
    }
    else{
        setError(false)
    }
   
}

 return (

<Grid container margin='10px 0' display='flex' spacing={1} width='100%'>
        {/* text field label */}
        <Grid item container
                xs={xs||4} md={mdlabel||3}
                margin='auto 0'
                // border='solid 1px orange'
                padding='0px 20px'
                justifyContent='flex-end'> 
            <Typography variant='generalDataFieldName' >
                {required ? (label+'*'): label}:
            </Typography>
        </Grid> 
         {/* text field value */}
        <Grid  item container  
        // border='solid 1px yellow' 
        xs={xs||8} md={mdTextField||8}>
            <TextField
                error={error}
                name={name}
                multiline={multiline}
                required = {required || false}
                style={{background: '#FFFFFF', minWidth:'70px',width: width || '100%'}}
                size='small'
                type= {type}
                InputProps={{ maxLength: 250 }}
                disabled={disabled}
                value={formValue}                                         
                onChange={handleChange}
                />
                
        </Grid>
    </Grid>
 )
}
export default FormTextField;