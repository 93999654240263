import logo from "./logo.svg";
import "./App.css";
import { Divider, Select, ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import Saira from "./fonts/Saira-Regular.ttf";
import SairaBold from "./fonts/Saira-Bold.ttf";

import "./styles.css";
import {
  AvatarGroup,
  Paper,
  Avatar,
  Tooltip,
  Box,
  Checkbox,
  Button,
  Grid,
  InputLabel,
  Link,
  SpeedDial,
  SpeedDialAction,
  Stack,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

import { PDFDownloadLink, PDFViewer, usePDF } from "@react-pdf/renderer";

import FormTextField from "./components/FormTextField";
import PDFDocument from "./components/PDFdoc";

const boxStyle = {
  padding: "10px",
  display: "flex",
  flexWrap: "wrap",
  flexGrow: 1,
  margin: "10px 10px",

  "& > :not(style)": {
    width: "100%",
    minWidth: 140,
    minHeight: 180,
    height: "100%",
    borderRadius: 3,
  },
};
function App() {
  const [Data, setData] = useState({
    type:'Anual',
    version: 'BUSINESS',
    company:'Mpservices'});
  const [currency, setCurrency] = useState("MXN");
  const [IVA, SetIva] = useState(16);
  const [QuoteData, setQuoteData] = useState({
    ID: 'Mexico',
    Curr:'MXN',
    Company: 'SOFTWARE PARA MANTENIMIENTO MP',
    address: 'Reforma 7, Naucalpan. Estado de México',
    Month: 425,
    Year: 4080,
    IVA: 16,
    IVALabel: 'IVA 16%',
    format:'EN'
  });

  const [pdfLoad, setPdfLoad] = useState(true);
  const [QuoteSended, setQuoteSended] = useState(false);
  const [folio, setFolio] = useState();

  const handleChange = (name, value) => {
    setData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const FormatDate = (date)=>{
        return(`${new Date(date).toLocaleDateString('es', {timeZone: 'America/Mexico_City'})} `)
    }

    const obligatoryFields=['users', 'company', 'email', 'contact']

    const SendData = ()=>{
      let datatosend = {...Data}
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(datatosend)
    };
    let validations = obligatoryFields.every((field) => {
      if (!Data[field]) {
        alert(
          'Falta Información:' + ' ' + field
        );
        return false;
      } else {
        return true;
      }
    });
    // validates the start date is before the end date of the contract
    if(validations){
      fetch('https://servicesmp.herokuapp.com/newQuote', requestOptions)
        .then(response => response.json())
        .then(data =>{
          console.log('data', data.insertedQuoteRecord.folio)
          setFolio(data.insertedQuoteRecord.folio)     
            }).then(()=>{
              setQuoteSended(true)
            });
    }
 
  }

let Today = FormatDate(new Date);

const listOfCountriesAndPrices = [{
  ID: 'Mexico',
  Curr:'MXN',
  Company: 'SOFTWARE PARA MANTENIMIENTO MP',
  address: 'REFORMA 7, CD BRISA, NAUCALPAN. ESTADO DE MÉXICO, MÉXICO',
  Month: 425,
  Year: 4080,
  IVA: 16,
  IVALabel: 'IVA 16%',
  format:'EN',
},
{
  ID: 'Colombia',
  Curr:'COP',
  Month: 109000,
  Year: 942400,
  IVA: 0,
  IVALabel:'Exluido de IVA*',
  address: 'Calle 34B Nro. 65D-02, Of 201, Medellín-Antioquia, COLOMBIA',
  Company: 'MPSOFTWARE COLOMBIA SAS',
  format:'es'
},
{
  ID: 'Chile',
  Curr:'CLP',
  Company: 'MPSOFTWARE CHILE',
  Month: 24300,
  Year: 234030,
  IVA: 19,
  address:'MARCHANT PEREIRA 150 OFICINA 901, COMUNA DE PROVIDENCIA, SANTIAGO, CHILE',
  IVALabel:'IVA 19%',
  format:'es'
}, 
{
  ID: 'Otro',
  Curr:'USD',
  Company: 'MPSERVICES LLC',
  Month: 25,
  Year: 240,
  IVA: 0,
  IVALabel:'No incluye Impuestos',
  format:'EN'
}]


  const handleChangeSelectCountry = (event) => {
    let data = listOfCountriesAndPrices.find(country => country.ID == event.target.value)
    setQuoteData(data)

  }


  const styles1 = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "#ffffff",
      fontFamily: "Saira",
      paddingHorizontal: 40,
      paddingVertical: 20,
      fontSize: 11,
    },
    row: {
      display: "flex",
      flexDirection: "row",
    },
    col: {
      display: "column",
    },
    rowR: {
      display: "flex",
      flexDirection: "row-reverse",
    },
    alignCenter: { display: "flex", alignItems: "center" },
    tAliRight: {
      textAlign: "right",
    },
    bold: {
      fontWeight: "bold",
    },
    title: {
      fontWeight: "bold",
      fontSize: 24,
    },
    text: {
      fontWeight: "regular",
      fontSize: 24,
    },
    line: {
      backgroundColor: "#d9dadb",
      height: 1,
      marginTop: "15px",
      marginBottom: "10px",
    },
    table: { 
      display: "table", 
      width: "auto", 
      borderStyle: "solid", 
      borderColor:'#d7dbe0',
      borderWidth: 1, 
      borderRightWidth: 0, 
      borderBottomWidth: 0 ,
      borderLeftWidth: 0 

    }, 
    tableRow: { 
      flexDirection: "row" 
    }, 
    tableCol: { 
      width: "100%", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderColor:'#d7dbe0',
      borderTopWidth: 0,
      borderRightWidth: 0, 
      borderBottomWidth: 0,
    }, 
    tableCell: { 
      margin: "auto", 
      marginTop: 4, 
      marginBottom: 4, 
      fontSize: 10 
    }, 
    tableCell1: { 
      marginTop: 10, 
      marginBottom: 4, 
      fontSize: 11,
      fontWeight:'bold'
    },
    tableCell2: { 
      marginTop: 14,
      marginBottom: 4, 
      marginLeft:250,
      fontSize: 10,
      fontWeight:'bold'

    },})

    

    Font.register({
      family: "Saira",
      fonts: [
        {
          src: Saira,
        },
        {
          src: SairaBold,
          fontWeight: "bold",
        },
      ],
    });

  const getCurrencyFormat = (number = 0) => {
    let format =QuoteData.format
    return number.toLocaleString(format, {
      style: "currency",
      currency: QuoteData.Curr,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

    const document = (
      <Document>
        <Page size="A4" style={styles1.page}>
          <View>
        <Image
            src="https://mpservices.s3.us-east-2.amazonaws.com/internal/mpservices+negro.png"
            style={{height: "30", width:'125'}}/>
          <View style={styles1.rowR}>
            <Text style={[styles1.bold,{fontSize:15}]}>Cotización</Text>
          </View>
          <View style={styles1.rowR}>
            <Text >Fecha: {Today}</Text>
          </View>
          <View style={styles1.rowR}>
            <Text >Folio: {folio}</Text>
          </View>
          </View>
          <View style={styles1.line} />
          <View style={styles1.row}>
            <Text style={{color:'grey'}}>De:</Text>
            </View>
            <View style={styles1.row}>
            <Text>
              {QuoteData.Company}
            </Text>
          </View>
          <View style={[styles1.row, {marginTop:6}]}>
            <Text style={{color:'grey'}}>Para:</Text>
            </View>
            <View style={styles1.row}>
            <Text>
              {Data.company}
            </Text>
          </View>
          <View style={styles1.row}>
            <Text>
              {Data.contact}
            </Text>
          </View>
          <View style={styles1.line} />
          <View style={styles1.table}> 

        <View style={styles1.tableRow}> 
          <View style={[styles1.tableCol, {width:'65%',  borderRightWidth: 1, backgroundColor:'#F5BD41'}]}> 
          <Text style={[styles1.tableCell,{fontWeight: 'bold', fontSize:11 }]}>Descripción del Producto</Text> 
          </View> 
          <View style={[styles1.tableCol, {width:'35%', backgroundColor:'#F5BD41'}]}> 
            <Text style={[styles1.tableCell,{fontWeight: 'bold', fontSize:11}]}>Precio</Text> 
          </View> 
        </View>
        {/* ROW 1: SUBSCRIPTION PRICE */}

        <View style={styles1.tableRow}> 
          <View style={[styles1.tableCol, {width:'65%', borderRightWidth: 1, }]}> 
            <Text style={styles1.tableCell1}>
             Suscripción {Data.type} Mpservices {Data.version} para {Data.users} Usuarios
            </Text> 
            <Text>
            • Módulo de Activos
            </Text> 
            <Text>
            • Módulo de Clientes
            </Text> 
            <Text>
            
          • Módulo de Actividades con plantillas ilimitadas
          </Text>
          <Text>
          • Módulo de Inventario
            </Text>  
            <Text>
          • Órdenes de trabajo ilimitadas
          </Text> 
           <Text>
          • Análisis de Información
          </Text> 
            <Text>
          • Aplicación móvil
          </Text>          
            <Text>
          • Centro de aprendizaje
          </Text> 
            <Text>
            {Data.version === 'BUSINESS' ? '• Almacenamiento ilimitado ' : '• Almacenamiento limitado a 10 GB'}
          </Text> 
            <Text>
          • Soporte por correo electrónico y llamada
            </Text> 
          </View> 
          <View style={[styles1.tableCol, {width:'35%'}]}> 
          {Data.type=='Anual' ? (
            <Text style={[styles1.tableCell,{justifyContent:'flex-end'}]}>
            {getCurrencyFormat(Data.users*QuoteData.Year)}
          </Text> 
          ) : (
          <Text style={[styles1.tableCell,{justifyContent:'flex-end'}]}>
            {getCurrencyFormat(Data.users*QuoteData.Month)}
          </Text> 
          )}
          </View> 
        </View> 
        
        {/* ROW 2: FREE COURSES AND IMPLEMENTATION PRICE */}

        {Data.type == 'Anual' && (
        <View style={[styles1.tableRow]}> 
            <View style={[styles1.tableCol, {width:'65%', borderRightWidth: 1 }]}>
            <Text style={styles1.tableCell1}>
              {Data.users > 6 ? 5 : 2} horas de capacitación e implementación en Línea
            </Text>  
            
            </View> 
            <View style={[styles1.tableCol, {width:'35%',  marginTop:10,}]}>
            <Text style={[styles1.tableCell,{justifyContent:'flex-end'}]}>
            {getCurrencyFormat(0)}
            </Text>  
            
            </View> 
        </View> 
        )}

    {QuoteData.Curr != 'USD' && (
        <View style={[styles1.tableRow]}> 
            <View style={[styles1.tableCol, {width:'65%', borderRightWidth: 1 }]}>
              
            <Text style={[styles1.tableCell2,{justifyContent:'flex-end'}]}>
              {QuoteData.IVALabel}
            </Text>  
           
            </View> 
            <View style={[styles1.tableCol, {width:'35%',  marginTop:10,}]}>
              {(Data.type == 'Anual') ? (
                 <Text style={[styles1.tableCell,{justifyContent:'flex-end'}]}>
                 {getCurrencyFormat(Data.users*QuoteData.Year*(QuoteData.IVA/100))}
               </Text> 
              ) : (
                <Text style={[styles1.tableCell,{justifyContent:'flex-end'}]}>
                {getCurrencyFormat(Data.users*QuoteData.Month*(QuoteData.IVA/100))}
              </Text> 
              )}
            </View> 
        </View> 
         )} 
        <View style={[styles1.tableRow]}> 
            <View style={[styles1.tableCol, {width:'65%', borderRightWidth: 1 }]}>
            <Text style={[styles1.tableCell2,{marginRight:'flex-end'}]}>
             Total 
            </Text>  
            
            </View> 
            <View style={[styles1.tableCol, {width:'35%',  marginTop:10, borderTopWidth:1}]}>
            {Data.type == 'Anual' ? (
                 <Text style={[styles1.tableCell,{justifyContent:'flex-end'}]}>
                 {getCurrencyFormat(Data.users*QuoteData.Year*(1+QuoteData.IVA/100))}
               </Text> 
              ) : (
                <Text style={[styles1.tableCell,{justifyContent:'flex-end'}]}>
                 {getCurrencyFormat(Data.users*QuoteData.Month*(1+QuoteData.IVA/100))}
              </Text> 
              )}    
            </View> 
        </View> 
      </View>
      <View style={styles1.row}>
            <Text style={[styles1.bold,{fontSize:11, marginTop: 40}]}>Forma de Pago:</Text>
          </View>
          <View style={styles1.row}>
            <Text style={{fontSize:11}}> Contado</Text>
          </View>
            {QuoteData.ID=='Colombia' && (
          <View style={styles1.row}>
            <Text style={{fontSize:10}}> * Según numeral 21 del ART.476 del E.T</Text>
          </View>
              )}
          {QuoteData.Curr=='USD' && (
          <View style={styles1.row}>
            <Text style={{fontSize:10}}> * Precios en USD. Lo precios no incluyen impuestos</Text>
          </View>
              )}

          <View style={[styles1.row ,{justifyContent:'center', marginTop:'100px'}]}>
           <Text style={{fontSize:10}}> {QuoteData.Company}</Text>
          </View>
          <View style={[styles1.row ,{justifyContent:'center', marginTop:'2px'}]}>
            <Text style={{fontSize:10}}> {QuoteData.address}</Text>
          </View>
          </Page>
      </Document>  )
        

  return (
    <ThemeProvider theme={theme}>
      <Grid container marginTop={5}>
        
        <Grid container justifyContent={"center"} direction={'column'} alignItems={'center'}>
            <img src="https://mpservices.s3.us-east-2.amazonaws.com/internal/mpservices+negro.png"
            style={{maxHeight: "50px"}}/>

          <Grid item >
          <Typography variant="h5">Obtén tu cotización de inmediato</Typography>
          </Grid>
          <Grid item  justifyContent={"center"}>
          <Typography variant="h6"> Ingresa tus datos y haz clic en OBTENER COTIZACIÓN para descargarla</Typography>
          </Grid>

          {!QuoteSended ?
           ( <Grid container width={'80%'}>
           <Grid  container width={'80%'} flexGrow={1}>
          
            <Box sx={boxStyle}>
              <Paper elevation={0} border="solid 1px">
              <Typography sx={{padding:'10px', fontWeight:'medium', fontSize:15}}>Paso 1: Llena la información de la suscripción</Typography>

                <FormTextField
                  name="users"
                  label={"Número de usuarios"}
                  value={Data.users}
                  setValueData={handleChange}
                  mdTextField={2}
                  mdlabel={4}
                  type={"number"}
                />
               
                <Grid
                  container
                  margin="10px 0"
                  display="flex"
                  spacing={1}
                  width="100%"
                >
                  <Grid
                    item
                    container
                    xs={4}
                    md={4}
                    margin="auto 0"
                    // border='solid 1px orange'
                    padding="0px 20px"
                    justifyContent="flex-end"
                  >
                    <Typography variant="generalDataFieldName">
                      Tipo:
                    </Typography>
                  </Grid>
                
                  <Select
                    size='small'
                    sx={{marginLeft:'10px',  width:'210px'}}
                    value={Data.type}
                    label="Moneda"
                    onChange={(e)=>{handleChange('type', e.target.value)}}
                  >
                    <MenuItem value={"Mensual"}>Mensual</MenuItem>
                    <MenuItem value={"Anual"}>Anual</MenuItem>
                  </Select>
                </Grid>
                {/* <FormTextField
                  name="folio"
                  label={"Folio"}
                  value={Data.folio}
                  setValueData={handleChange}
                  mdTextField={3}
                  mdlabel={4}
                  type={"number"}
                /> */}
              </Paper>
            </Box>
            </Grid>
            <Grid  container  width={'80%'}  flexGrow={1}>
            <Box sx={boxStyle}>
              <Paper elevation={0} border="solid 1px">
              <Typography sx={{padding:'10px', fontWeight:'medium', fontSize:15}}>Paso 2: Ingresa la información de la Empresa</Typography>
              <Grid
                  container
                  margin="10px 0"
                  display="flex"
                  spacing={1}
                  width="100%"
                >
                  <Grid
                    item
                    container
                    xs={4}
                    md={4}
                    margin="auto 0"
                   
                    // border='solid 1px orange'
                    padding="0px 20px"
                    justifyContent="flex-end"
                  >
                    <Typography variant="generalDataFieldName">
                      País:
                    </Typography>
                  </Grid>
                 
                  <Select
                  sx={{marginLeft:'10px',  width:'210px'}}
                    size='small'
                    value={QuoteData.ID}
                    label="Moneda"
                    onChange={handleChangeSelectCountry}
                  >
                    <MenuItem value={"Mexico"}>México</MenuItem>
                    <MenuItem value={"Colombia"}>Colombia</MenuItem>
                    <MenuItem value={"Chile"}>Chile</MenuItem>
                    <MenuItem value={"Otro"}>Otro (USD)</MenuItem>

                  </Select>
                </Grid>
                <FormTextField
                  name="company"
                  label={"Nombre de la Empresa"}
                  value={Data.comany}
                  setValueData={handleChange}
                  mdTextField={6}
                  mdlabel={4}
                />
                <FormTextField
                  name="contact"
                  label={"Nombre del Contacto"}
                  value={Data.contact}
                  setValueData={handleChange}
                  mdTextField={6}
                  mdlabel={4}
                />
                    <FormTextField
                  name="email"
                  label={"Email del Contacto"}
                  value={Data.email}
                  setValueData={handleChange}
                  mdTextField={6}
                  mdlabel={4}
                />
              </Paper>
            </Box>
            </Grid>
            </Grid>) :
            ( 
              <Grid padding={10}>
                <Typography variant="h6"> Gracias por tu solicitud. Descarga tu cotización. </Typography>
                          
              </Grid>)
                          }

             
        </Grid>
       

        <Grid container justifyContent={"center"}>
        {!QuoteSended ? ( <Button variant='contained' onClick={()=>SendData()}>
            Obtener Cotización
          </Button> ): (
             <PDFDocument name={'COT-'+folio+'-'+Data.company} document={document} setPdfLoad={setPdfLoad} />
          ) }
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default App;
